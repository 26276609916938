function objectifyForm(formArray) {
    var returnArray = {};
    //For each item in the form array
    for (var i = 0; i < formArray.length; i++) {
        var name = formArray[i].name;
        //If the key of the array contains a '.' we need to have a object in a object
        if (name.indexOf('.') > -1) {
            //Split the key to get the object names
            var splitted = name.split(/\.(.+)/);
            //If the object doesn't exists yet create one
            if (returnArray[splitted[0]] === undefined) {
                returnArray[splitted[0]] = {};
            }
            returnArray[splitted[0]][splitted[1]] = formArray[i].value;
        } else {
            returnArray[name] = formArray[i].value;
        }
    }
    return returnArray;
}
