$(function() {
    if (isMobile) {
        var tl = new TimelineLite();
        tl.add(
            TweenMax.staggerFrom('.navigation li', 0.2, { ease: Cubic.easeOut, opacity: 0, y: -8, delay: 0.04 }, 0.08)
        );
        tl.pause();
    }

    $('.hamburger').click(function() {
        var $this = $(this);
        $('body').toggleClass('nav-expanded');

        if ($this.hasClass('active')) {
            $this.removeClass('active');
            tl.restart().pause();
        } else {
            $this.addClass('active');
            tl.play();
        }
    });
});
