// The video
var video = document.getElementById('commercial');
if (video) {
    video.pause();
}

// Check if the entered date is in fact a valid and existing date
function isValidDate(y, m, d) {
    if (y.toString().length == 4) {
        var date = new Date(y, m - 1, d);

        if (Object.prototype.toString.call(date) === '[object Date]') {
            if (isNaN(date.getTime())) {
                return false;
            } else {
                var convertedDate = '' + date.getFullYear() + (date.getMonth() + 1) + date.getDate();
                var givenDate = '' + y + m + d;
                return givenDate == convertedDate;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// Setting cookie, checking checkbox
function setCookieAndRedirect() {
    var remember = document.getElementById('remember').checked;
    var expires = '';
    if (remember) {
        var d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        expires = '; expires=' + d.toUTCString();
    }

    document.cookie = 'deklok-age-verification=allowed;path=/;samesite=none;secure' + expires;
    document.body.className = document.body.className.replace('gated', '');
    window.scrollTo(0, 0);
}

// checks if userAgent is probably a bot
function isBot(userAgent) {
    var pattern = RegExp(/bot|google|baidu|bing|msn|teoma|slurp|yandex/i);
    return userAgent.match(pattern);
}

// Checking cookie
function checkCookie() {
    if (isBot(window.navigator.userAgent)) {
        return;
    }
    window.scrollTo(0, 0);
    var pattern = RegExp('deklok-age-verification=.[^;]*');
    var matched = document.cookie.match(pattern);
    if (matched) {
        var cookie = matched[0].split('=');
        if (cookie[1] !== 'allowed') {
            if (document.querySelector('.agegate') !== null) {
                document.body.className += ' ' + 'gated';
            }
        }
    } else {
        if (document.querySelector('.agegate') !== null) {
            document.body.className += ' ' + 'gated';
        }
    }
}

//Check if the age of the given date is >= 18 years
//Set cookie if old enough
function validAge(date) {
    var allowedDate = new Date();
    allowedDate.setHours(0, 0, 0, 0);
    allowedDate.setFullYear(allowedDate.getFullYear() - 18);

    $('.error')
        .html('')
        .removeClass('active');

    if (date <= allowedDate) {
        setCookieAndRedirect();
    } else {
        $('.error')
            .html('Je bent te jong om deze site te bezoeken')
            .addClass('active');
    }
}

checkCookie();

(function() {
    var ageCheckButton = document.getElementById('ageCheck');
    if (ageCheckButton !== null) {
        ageCheckButton.onclick = function(e) {
            e.preventDefault();

            var day = parseInt(document.getElementById('day').value);
            var month = parseInt(document.getElementById('month').value);
            var year = parseInt(document.getElementById('year').value);

            var birthdate = new Date(year, month - 1, day); //Month -1 because month starts with 0

            if (isValidDate(year, month, day)) {
                validAge(birthdate);
            } else {
                $('.error')
                    .html('Ongeldige geboortedatum!')
                    .addClass('active');
            }
        };
    }
})();

$(function() {
    $('.form-control').keyup(function(event) {
        var newValue = $(this).val();
        //see if the textbox had its value changed
        if ($(this).attr('prevValue') !== newValue) {
            //see if the number of entered characters is equal or greater
            //than the allowable maxlength
            if (newValue.length >= $(this).attr('maxlength')) {
                //set focus on the next field with autotab class

                $(this)
                    .next()
                    .next()
                    .focus();
            }
            //save newly entered value for the next execution of this event
            $(this).attr('prevValue', newValue);
        }
    });
});
