// Handle tour contact form and default contact form

$(function() {
    //Get the elements for later use
    var $mailForm = $('#contactForm');

    $mailForm.each(function(index, element) {
        var $form = $(element);

        $form.validate({
            errorPlacement: function(error, element) {
                error.insertAfter(element);
            },
            rules: {
                phone: { phone: true },
                houseNumber: { greaterThanZero: true },
                units: { integerGreaterThanZero: true },
                zipCode: { zipcode: true },
            },
        });

        $form.find('.btn').on('click', function(e) {
            e.preventDefault();
            var enctype = $form.attr('enctype');
            var $alertBox = $form.find('.alert');

            if ($form.valid()) {
                $form.addClass('processing');

                var successCallback = function() {
                    $form.removeClass('processing');

                    showAlert($alertBox.data('title-success'), '', false, $form);
                    $('.contactform,.tour-form')
                        .find('.form-wrapper')
                        .remove();
                };

                var failCallback = function() {
                    $form.removeClass('processing');
                    showAlert($alertBox.data('title-error'), '', true, $form);
                };

                var arr = $form.serializeArray();
                var obj = objectifyForm(arr);
                var json1 = JSON.stringify(obj);
                var json = json1.replace('g-recaptcha-response', 'recaptcha');

                $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: json,
                    dataType: 'json',
                    contentType: 'application/json',
                    success: successCallback,
                    error: failCallback,
                });
            } else {
                $form.removeClass('processing');
                // Scroll to first error in form
                $('html, body').animate(
                    {
                        scrollTop: $('select.error, input.error, textarea.error').offset().top - 30,
                    },
                    'fast'
                );
            }
        });
    });

    function showAlert(title, message, error, form) {
        var color = 'green';
        var alertBox = form.find('.alert');

        if (error) {
            alertBox.addClass('error');
            color = 'red';
        } else {
            alertBox.addClass('success');
        }
        var html = '<p class="' + color + ' text-center">' + title + '</p>' + message;
        alertBox.html(html);
        alertBox.show();

        $('html, body').animate({ scrollTop: $('.contact-form, .tour-form').offset().top }, 'fast');
    }
});

var recaptchaCallback = function() {
    renderForm('contactForm-recaptcha', 'submit-form');
};

var renderForm = function(id, submitButtonId) {
    if (document.getElementById(id)) {
        var widgetId = grecaptcha.render(id, {
            sitekey: '6Le01pkhAAAAABvtKWfhd4yjQG0NQtmqL1iqlYNm',
            callback: function() {
                $('#' + submitButtonId).removeAttr('disabled');
            },
            'expired-callback': function() {
                $('#' + submitButtonId).attr('disabled', 'disabled');
            },
        });
        $('#' + id).data('rcid', widgetId + '');
    }
};
